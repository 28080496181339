
.FileCard {
    width: 250px;
    height: 250px;
    cursor: pointer;
    display: grid;
    grid-template-rows: 40px 100px 100px;
    grid-template-columns: 100%;
    justify-content: space-evenly;
    align-items: center;
    padding: 5px;
}

.FileCardIcon {
    padding: 10px;
}

.FolderContainer {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-gap: 10px;
    padding-bottom: 20px;
}

.BreadcrumbContainer {
    display: flex;
    gap: 5px;
}

.CardIcons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 4px;
}

li {
    text-align: left;
}